import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useMemo, useState } from 'react'
import {
  AlertProps,
  EmptyRecords,
  Lozenge,
  LozengeProps,
  priceFormat,
  Toast
} from '@nzsb/shopnx-ui'
import type { UrlObject } from 'url'
import { IInvoicesListData } from 'lib/actions'
import { invoiceStatusData, invoiceTypeLabels } from 'lib/static/orderInvoicesStaticData'
import { changeRouter, convertDate } from 'lib/utilities'

import { ActionButtonPanel, DataTable, DateHeader } from 'components/molecules'

export interface InvoicesAndCreditsDataTableProps {
  componentId?: string
  invoiceListData: IInvoicesListData
  isLoading?: boolean
  onDownloadButtonClick: (id: string) => void
  onEmailButtonClick: (id?: string) => void
  onPayNowButtonClick: (id: string) => void
  selectedRows: string[]
  setSelectedRows: any
}

interface ActionButtonsProps {
  componentId?: string
  invoiceNumber: string
  invoiceStatus: number
  isDownloadPending?: boolean
  isPdfAvailable: boolean
  onDownloadButtonClick: (id: string) => void
  onEmailButtonClick: (id?: string) => void
  onPayNowButtonClick: (id: string) => void
  viewUrl: UrlObject
}

const ActionButtons: FC<ActionButtonsProps> = ({
  componentId,
  invoiceNumber,
  invoiceStatus,
  isDownloadPending,
  isPdfAvailable,
  onDownloadButtonClick,
  onEmailButtonClick,
  onPayNowButtonClick,
  viewUrl
}: ActionButtonsProps) => {
  const router = useRouter()

  return (
    <ActionButtonPanel
      componentId={componentId ?? invoiceNumber}
      viewButton={{
        onClick: () => router.push(viewUrl)
      }}
      emailButton={{
        onClick: () => onEmailButtonClick(invoiceNumber),
        disabled: !isPdfAvailable
      }}
      downloadButton={{
        onClick: () => onDownloadButtonClick(invoiceNumber),
        disabled: !isPdfAvailable,
        loading: isDownloadPending
      }}
      payNowButton={{
        disabled: [1, 5].includes(invoiceStatus),
        onClick: () => onPayNowButtonClick(invoiceNumber)
      }}
    />
  )
}

export const InvoicesAndCreditsDataTable: FC<InvoicesAndCreditsDataTableProps> = ({
  componentId,
  invoiceListData,
  isLoading,
  onDownloadButtonClick,
  onEmailButtonClick,
  onPayNowButtonClick,
  selectedRows,
  setSelectedRows
}: InvoicesAndCreditsDataTableProps) => {
  const router = useRouter()
  const params = useSearchParams()

  const paramEntries = useMemo(() => Object.fromEntries(params.entries()), [params])
  const pageSizeParam = params.get('pageSize') ?? '30'
  const dateSortParam = params.get('sortType') ?? 'desc'
  const notify = (props: AlertProps) => Toast(props)

  const getCellContent = (row: any, rowHeader: string) => {
    const rowIndex = invoiceListData.pageSize * (invoiceListData.pageIndex - 1) + row.index + 1

    const searchParamString = new URLSearchParams({ rowIndex, ...paramEntries }).toString()
      ? `?${new URLSearchParams({ rowIndex, ...paramEntries }).toString()}`
      : ''

    switch (rowHeader) {
      case 'Date':
        return <span className='text-N-600 font-500'>{convertDate(row?.original?.date)}</span>
      case 'Customer PO Reference':
        return (
          <a
            className='text-O-500 underline'
            href={`/my-account/invoices-credits/${row.original.invoiceNumber}${searchParamString}`}>
            {row.original.customerPOReference}
          </a>
        )
      case 'Invoice Number':
        return (
          <a
            className='text-O-500 underline'
            href={`/my-account/invoices-credits/${row.original.invoiceNumber}${searchParamString}`}>
            {row.original.invoiceNumber}
          </a>
        )
      case 'Type':
        return (
          <span className='text-N-600 font-500'>
            {invoiceTypeLabels[row?.original?.invoiceType as keyof typeof invoiceTypeLabels]}
          </span>
        )
      case 'Invoice Total':
        return (
          <span className='text-N-600 font-500'>${priceFormat(row?.original?.totalWithGST)}</span>
        )
      case 'Balance':
        return (
          <span className='text-N-600 font-500'>${priceFormat(row?.original?.balanceWithGST)}</span>
        )
      case 'Status':
        return (
          <div className='flex items-center'>
            <Lozenge
              appearance='subtle'
              variant={
                invoiceStatusData[row.original.status as keyof typeof invoiceStatusData]
                  ?.variant as LozengeProps['variant']
              }>
              {invoiceStatusData[row.original.status as keyof typeof invoiceStatusData]?.label}
            </Lozenge>
          </div>
        )
      case 'Action':
        return (
          <div className='flex items-center justify-center'>
            <ActionButtons
              invoiceNumber={row.original.invoiceNumber}
              invoiceStatus={row.original.status}
              onDownloadButtonClick={onDownloadButtonClick}
              onEmailButtonClick={onEmailButtonClick}
              onPayNowButtonClick={onPayNowButtonClick}
              isDownloadPending={row.original.isDownloadPending}
              isPdfAvailable={row.original.isPdfAvailable}
              viewUrl={{
                pathname: `/my-account/invoices-credits/${row.original.invoiceNumber}`,
                query: { rowIndex, ...paramEntries }
              }}
            />
          </div>
        )
      case 'Mobile':
        return (
          <div className='flex flex-col text-sm font-sans py-1'>
            <div className='flex items-center py-1'>
              <span className='w-[162px] text-N-500 font-400'>Date:</span>
              <span className='text-N-600 font-500'>{convertDate(row?.original?.date)}</span>
            </div>
            <div className='flex items-center py-1'>
              <span className='w-[162px] text-N-500 font-400'>Customer PO Ref.:</span>
              <a
                className='text-O-500 underline'
                href={`/my-account/invoices-credits/${row.original.invoiceNumber}${searchParamString}`}>
                {row.original.customerPOReference}
              </a>
            </div>
            <div className='flex items-center py-1'>
              <span className='w-[162px] text-N-500 font-400'>Invoice Number:</span>
              <a
                className='text-O-500 underline'
                href={`/my-account/invoices-credits/${row.original.invoiceNumber}${searchParamString}`}>
                {row.original.invoiceNumber}
              </a>
            </div>

            <div className='flex items-center py-1'>
              <span className='w-[162px] text-N-500 font-400'>Type:</span>
              <span className='text-N-600 font-500'>
                {invoiceTypeLabels[row?.original?.invoiceType as keyof typeof invoiceTypeLabels]}
              </span>
            </div>

            <div className='flex items-center py-1'>
              <span className='w-[162px] text-N-500 font-400'>Status:</span>
              <Lozenge
                appearance='subtle'
                variant={
                  invoiceStatusData[row.original.status as keyof typeof invoiceStatusData]
                    ?.variant as LozengeProps['variant']
                }>
                {invoiceStatusData[row.original.status as keyof typeof invoiceStatusData]?.label}
              </Lozenge>
            </div>
            <div className='flex items-center py-1'>
              <span className='w-[162px] text-N-500 font-400'>Invoice Total:</span>
              <span className='text-N-600 font-500'>
                ${priceFormat(row?.original?.totalWithGST)}
              </span>
            </div>

            <div className='flex items-center py-1'>
              <span className='w-[162px] text-N-500 font-400'>Balance:</span>
              <span className='text-N-600 font-500'>
                ${priceFormat(row?.original?.balanceWithGST)}
              </span>
            </div>

            <div className='flex items-center py-1'>
              <span className='w-[162px] text-N-500 font-400'>Action:</span>
              <ActionButtons
                componentId={`mobile-action-buttons-${row.original.invoiceNumber}`}
                isDownloadPending={row.original.isDownloadPending}
                isPdfAvailable={row.original.isPdfAvailable}
                invoiceNumber={row.original.invoiceNumber}
                invoiceStatus={row.original.status}
                onDownloadButtonClick={onDownloadButtonClick}
                onEmailButtonClick={onEmailButtonClick}
                onPayNowButtonClick={onPayNowButtonClick}
                viewUrl={{
                  pathname: `/my-account/invoices-credits/${row.original.invoiceNumber}`,
                  query: { rowIndex, ...paramEntries }
                }}
              />
            </div>
          </div>
        )
      default:
        return ''
    }
  }

  const cellHeaderClassName = `leading-[16px] h-[44px] z-0 !bg-N-50 min-w-[153px] text-left`

  const DESKTOP_COLUMNS = useMemo(() => {
    const columnData = [
      { header: () => DateHeader(), accessor: 'date', label: 'Date' },
      {
        header: 'Customer PO Reference',
        accessor: 'customerPOReference',
        label: 'Customer PO Reference',
        cellClassName: '!min-w-[112px]'
      },
      { header: 'Invoice Number', accessor: 'invoiceNumber', label: 'Invoice Number' },
      { header: 'Type', accessor: 'type', label: 'Type' },
      { header: 'Status', accessor: 'status', label: 'Status' },
      { header: 'Invoice Total', accessor: 'totalWithGST', label: 'Invoice Total' },
      { header: 'Balance', accessor: 'balanceWithGST', label: 'Balance' },
      {
        header: 'Action',
        accessor: 'action',
        label: 'Action',
        cellClassName: '!min-w-[200px] !max-w-[200px]'
      }
    ]

    return columnData.map(({ header, accessor, label, cellClassName = '' }) => ({
      Header: header,
      accessor: accessor,
      cellHeaderClassName: `${cellHeaderClassName} ${cellClassName}`,
      cellClassName: 'h-[64px]',
      Cell: ({ row }: any) => getCellContent(row, label)
    }))
  }, [
    dateSortParam,
    invoiceListData,
    paramEntries,
    invoiceListData?.pageIndex,
    invoiceListData?.pageSize
  ])

  const MOBILE_COLUMNS = useMemo(() => {
    return [
      {
        Header: ' ',
        accessor: ' ',
        Cell: ({ row }: any) => {
          return getCellContent(row, 'Mobile')
        }
      }
    ]
  }, [invoiceListData, paramEntries, invoiceListData?.pageIndex, invoiceListData?.pageSize])

  const [selectedOriginalRowsIndexes, setSelectedOriginalRowsIndexes] = useState<any>()

  useEffect(() => {
    const selectedRowsIndexes: any = []
    const selectedInvoiceNumbers = selectedRows
      ?.filter((row: any) => row?.rowSelected)
      ?.map((row: any) => row?.invoiceNumber)

    invoiceListData?.invoiceList?.forEach((item: any, index: number) => {
      if (selectedInvoiceNumbers?.includes(item?.invoiceNumber)) {
        selectedRowsIndexes?.push(index.toString())
      }
    })
    setSelectedOriginalRowsIndexes(selectedRowsIndexes)
  }, [invoiceListData, selectedRows])

  const notifySelectionLimit = () =>
    notify({
      status: 'Info',
      alertHeader: 'Selection Limit Reached',
      alertBody: 'You have reached the maximum number of records for performing a bulk action.'
    })

  const tableProps = {
    enablePagination: true,
    data: invoiceListData?.invoiceList || [],
    showingCount: invoiceListData?.invoiceList?.length || 0,
    totalCount: invoiceListData?.resultCount,
    onPerPageChange: (perPage: number) => {
      changeRouter(router, 'pageSize', perPage.toString(), ['page'])
    },
    defaultSelectedOption: pageSizeParam,
    perPageSelections: ['30', '60', '90'],
    paginationProps: {
      pageIndex: invoiceListData?.pageIndex,
      pageSize: invoiceListData?.pageSize,
      resultsCount: invoiceListData?.resultCount,
      totalPages: invoiceListData?.totalPages,
      limitedPerPageCount: true,
      onPageChange: (index: number) => {
        changeRouter(router, 'page', index.toString())
      }
    },
    enableRowSelection: true,
    onAllRowsSelect: (rows: any[], isChecked: boolean) => {
      if (isChecked) {
        const rowsInRange = rows.slice(0, 30)

        setSelectedRows(rowsInRange.map((row: any) => ({ ...row?.original, rowSelected: true })))
        notifySelectionLimit()
      } else {
        setSelectedRows([])
      }
    },
    onRowSelect: (row: any) => {
      if (row.rowSelected) {
        setSelectedRows((prevSelectedRows: any) => {
          if (prevSelectedRows?.length < 29) {
            return [...prevSelectedRows, row]
          } else if (prevSelectedRows?.length === 29) {
            notifySelectionLimit()
            return [...prevSelectedRows, row]
          } else {
            notifySelectionLimit()
            return [...prevSelectedRows]
          }
        })
      } else {
        setSelectedRows((prevSelectedRows: any) =>
          prevSelectedRows.filter((item: any) => item?.invoiceNumber !== row?.invoiceNumber)
        )
      }
    },
    selectedRowIds: selectedOriginalRowsIndexes
  }

  return (
    <div className='flex w-full h-fit' data-component-id={`order-history-table-${componentId}`}>
      {/* Desktop View */}
      <DataTable
        mainDivClassName='hidden lg:!block'
        columns={DESKTOP_COLUMNS}
        {...tableProps}
        isLoading={isLoading}
        emptyStateContent={
          <div className='w-full flex flex-col justify-start items-center h-fit pt-20'>
            <EmptyRecords />
            <span className='font-800 text-h3'>No records found</span>
            <span className='text-sm text-N-700'>
              Sorry! There are currently no invoices/credits to display.
            </span>
          </div>
        }
        numOfTotalSelectableRows={30}
      />
      {/* Mobile, Tab View */}
      <DataTable
        mainDivClassName='lg:!hidden'
        isShowHeaders={false}
        columns={MOBILE_COLUMNS}
        isLoading={isLoading}
        emptyStateContent={
          <div className='w-full flex flex-col justify-start items-center h-fit pt-20'>
            <EmptyRecords />
            <span className='font-800 text-h3'>No records found</span>
            <span className='text-sm text-N-700'>
              Sorry! There are currently no invoices/credits to display.
            </span>
          </div>
        }
        {...tableProps}
      />
    </div>
  )
}

export default InvoicesAndCreditsDataTable
